import React from "react"
import { Fade } from "react-reveal"

import "./BlogCard.css"

export default function BlogCard({ blog }) {

  function openUrlInNewTab(url, name) {
    if (!url) {
      console.log(`URL for ${name} not found`)
      return
    }
    var win = window.open(url, "_blank")
    win.focus()
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div>
        <div className="container">
          <div className="square" onClick={() => openUrlInNewTab(blog.url, blog.title)} onKeyDown={() => openUrlInNewTab(blog.url, blog.title)}>
            <img src={blog.image} alt="blog" className="blog-card-image mask" />
            <div className="blog-card-title">{blog.title}</div>
            <p className="blog-card-subtitle">{blog.description}</p>
          </div>
        </div>
      </div>
    </Fade>
  )
}
