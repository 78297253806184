import React, { useEffect, useState } from "react"

import Header from "../components/header/Header"
import Greeting from "./greeting/Greeting"
import About from "./about/About"
import Experience from "./experience/Experience"
import Work from "./work/Work"
import Blogs from "./blogs/Blogs"
import Contact from "./contact/Contact"
import Footer from "../components/footer/Footer"
import SplashScreen from "./splashScreen/SplashScreen"
import {splashScreen} from "../portfolio"
import "./Main.css"

const Main = () => {
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true)

  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      )
      return () => {
        clearTimeout(splashTimer)
      }
    }
  }, [])

  return (
    isShowingSplashAnimation && splashScreen.enabled ? (
      <SplashScreen />
    ) : (
    <div>
      <Header />
      <Greeting />
      <About />
      <Experience />
      <Work />
      <Blogs />
      <Contact />
      <Footer />
    </div>
    )
  )
}

export default Main