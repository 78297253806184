import React from "react"
import { Fade } from "react-reveal"
import { StaticImage } from "gatsby-plugin-image"

import "./About.css"
import SoftwareSkills from "../../components/softwareSkills/SoftwareSkills"
import { skillsSection } from "../../portfolio"

export default function About() {

  if (!skillsSection.display) {
    return null
  }
  
  return (
    <div className="main" id="about">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
            <StaticImage alt="Working" src="../../assets/images/working.webp" />
            {/* <img alt="Working" src={require("../../assets/images/working.webp")}></img> */}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1 className="skills-heading">{skillsSection.title} </h1>
            <p className="subTitle skills-text-subtitle">{skillsSection.subTitle}</p>
            <p className="subTitle skills-text-subtitle">{skillsSection.skillsPreamble}</p>
            <SoftwareSkills />
          </div>
        </Fade>
      </div>
    </div>
  )
}
