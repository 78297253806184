import React from "react"
import { Fade } from "react-reveal"
import Headroom from "react-headroom"

import "./Header.css"
import {
  greeting,
  skillsSection,
  experience,
  openSource,
  blogSection,
  contactInfo
} from "../../portfolio"

function Header() {

  const loc = typeof window !== `undefined` ? window.location.origin : `#`
  const viewAbout = skillsSection.display
  const viewExperience = experience.display
  const viewWork = openSource.display
  const viewBlogs = blogSection.display
  const viewContact = contactInfo.display

  return (
    <Headroom>
      <Fade top duration={1000} distance="20px">
        <div>
          <header className="header">
            <a href={`${loc}`} className="logo">
              <span className="logo-name">{greeting.username}</span>
            </a>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu">
              {viewAbout && (<li>
                <a href="#about">About</a>
              </li>)}
              {viewExperience && (<li>
                <a href="#experience">Experience</a>
              </li>)}
              {viewWork && (<li>
                <a href="#work">Work</a>
              </li>)}
              {viewBlogs && (<li>
                <a href="#blogs">Blogs</a>
              </li>)}
              {viewContact && (<li>
                <a href="#contact">Contact</a>
              </li>)}
            </ul>
          </header>
        </div>
      </Fade>
    </Headroom>
  )
}
export default Header
