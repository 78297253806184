import React from "react"
import "./SocialMedia.css"
import { socialMediaLinks } from "../../portfolio"

export default function socialMedia() {
  if (!socialMediaLinks.display) {
    return null
  }

  return (
    <div className="social-media-div">
      <a href={socialMediaLinks.github} className="icon-button github" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-github"></i>
        <span></span>
      </a>
      <a href={socialMediaLinks.linkedin} className="icon-button linkedin" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin-in"></i>
        <span></span>
      </a>
      <a href={socialMediaLinks.twitter} className="icon-button twitter" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
        <span></span>
      </a>
      <a href={`mailto:${socialMediaLinks.gmail}`} className="icon-button mail" target="_blank" rel="noopener noreferrer">
        <i className="far fa-envelope"></i>
        <span></span>
      </a>
      <a href={socialMediaLinks.medium} className="icon-button medium" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-medium"></i>
        <span></span>
      </a>
      <a href={socialMediaLinks.dev} className="icon-button dev" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-dev"></i>
        <span></span>
      </a>
    </div>
  )
}
