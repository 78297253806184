import splashAnimation from "./assets/lottie/splashAnimation"

// Splash Screen
const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
}

const greeting = {
  username: "PRATIKMS",
  title: "Hello, World! 👋 I'm Pratik Shivaraikar",
  subTitle:
    "I'm a passionate Software Engineer based in Pune, India specializing in building exceptional, high-quality software products.",
  resumeLink: "/resume.pdf"
}

const socialMediaLinks = {
  linkedin: "https://www.linkedin.com/in/pratik-shivaraikar/",
  github: "https://github.com/pratikms",
  gmail: "contact@pratikms.com",
  twitter: "https://twitter.com/@pmshivaraikar",
  medium: "https://medium.com/@pratik.shivaraikar",
  dev: "https://dev.to/pratikms",
  display: true
}

const skillsSection = {
  title: "About Me",
  subTitle: "I have a professional experience of working in the Storage, Security and Wireless domains. Apart from that, I've also worked on quite a few open-source projects ranging from web development to game development.",
  skillsPreamble: "Here are a few technologies that I've worked on:",
  
  softwareSkills: [
    {
      skillName: "Go",
      fontAwesomeClassname: "fab fa-golang"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "Node.js",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "PHP",
      fontAwesomeClassname: "fab fa-php"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "React",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "Firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "MySQL",
      fontAwesomeClassname: "fas fa-database"
    },
  ],
  display: true
}

const experience = {
  experience: [
    {
      key: 4,
      role: "Staff Engineer",  
      company: "Lenovo",
      companyLogo: "./assets/images/lenovo.webp",
      companyLogoName: "lenovo",
      companyHomepage: "https://www.lenovo.com/in/en/data-center/",
      background: "rgb(230, 22, 29)",
      date: "Sep 2021 – Present",
      desc: "Working on Lenovo XClarity™ data-center management solutions",
      descBullets: [
        "Implement fetching, parsing, and compiling SMART data of SATA and SAS drives to detect and report various indicators of drive reliability",
        "Integrator of Schneider's EcoStruxure IT Expert with Lenovo XClarity Orchestrator",
        "Designed, built and provisioned a syncing mechanism for Schneider Electric’s EcoStruxure IT Expert and Lenovo XClarity Orchestrator"
      ]
    },
    {
      key: 3,
      role: "Principal Development Engineer",  
      company: "Calsoft",
      companyLogo: "./assets/images/calsoft.webp",
      companyLogoName: "calsoft",
      companyHomepage: "https://calsoftinc.com/",
      background: "rgb(230, 22, 29)",
      date: "Jun 2019 – Sep 2021",
      desc: "Contracted to Lenovo for working on Lenovo XClarity™ data-center management solutions",
      descBullets: [
        "Major contributor of the Third-party Integrations Team, working on data-forwarding micro-services",
        "Active developer to Lenovo's various open-source projects like PyLXCA, Lenovo Ansible, etc.",
        "Lead contributor for integrating SIEM tools like Splunk to collect, analyze and derive meaningful insights from events collected from Lenovo hardware"
      ]
    },
    {
      key: 2,
      role: "Software Engineer",   
      company: "Quick Heal",
      companyLogo: "./assets/images/quickheal.webp",
      companyLogoName: "quickheal",
      companyHomepage: "https://www.quickheal.com/",
      background: "rgb(244, 100, 28)",
      date: "Feb 2018 – Jun 2019",
      desc: "Worked on Shared Services of Licensing and Activation across all Quick Heal products",
      descBullets: [
        "Developed Partner Portal for Key Generation and Activation",
        "Designed and implemented a highly-performant Geo-location API to be used by all Quick Heal products and saving considerable YOY expenditure",
        "Delivered multiple POCs for supporting server migration from on-prem to cloud"
      ]
    },
    {
      key: 1,
      role: "Software Engineer",  
      company: "Wifi-soft Solutions",
      companyLogo: "./assets/images/wifi-soft.webp",
      companyLogoName: "wifi-soft",
      companyHomepage: "https://www.indionetworks.com/",
      background: "rgb(5, 52, 91)",
      date: "Jun 2015 – Feb 2018",
      desc: "Worked on Enterprise, Hotspot and IoT solutions to monetize WiFI",
      descBullets: [
        "Developed Unibox 3.0 — a multicontroller — from ground up",
        "Developed real-time dashboard providing analytics collected from all the IoT devices for the Sensors product",
        "Implemented Kibo Partner Management (KPM) — a complete solution for Partners, Distributers and Vendors to manage their hotspots"
      ]
    },
  ],
  display: true
}

const openSource = {
  githubConvertedToken: process.env.GATSBY_GITHUB_ACCESS_TOKEN,
  githubUserName: "pratikms",
  display: true
}

const blogSection = {
  title: "Blogs",
  subtitle: "Whenever I find time, I also like to write about whatever I have learned:",
  url: "https://blog.pratikms.com",
  display: true
}

const contactInfo = {
  title: "Get In Touch",
  subtitle: "Although I'm not currently looking for freelance opportunities, my inbox is always open. Whether for a potential project or just to say hi, I'll try my best to answer your email!",
  // number: "+92-3243454077",
  emailAddress: "contact@pratikms.com",
  display: true
}

export { splashScreen, greeting, socialMediaLinks, skillsSection, experience, openSource, blogSection, contactInfo }
